import React, { useEffect } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { ErrorBoundary } from '@r-client/republic/feature/errors';
import { GlobalPage, UsPage } from '@r-client/republic/feature/global';
import { useGdprQuery } from '@r-client/republic/feature/home-v2';
import { Main, MainLoggedOut } from '@r-client/republic/feature/layout';
import {
  MainLayout,
  useRegionContext,
  withRegionContext,
} from '@r-client/republic/feature/main-layout';
import { defaultCoverImage, PageSEOMeta } from '@r-client/republic/feature/seo';
import { ZendeskScript } from '@r-client/republic/feature/zendesk';
import { theme } from '@r-client/republic/ui/theme';
import {
  useAnalytics,
  useBackendAnalyticsIntegration,
} from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import {
  HeroCarousel,
  useCarouselsQuery,
} from '@r-client/shared/feature/carousel';
import { toAbsoluteURL, useAppConfig } from '@r-client/shared/util/core';

// Main Component
const GlobalHome = observer(function GlobalHome() {
  const { applicationURLOrigin, zendeskKey } = useAppConfig();
  const { viewer } = useAuth();
  const router = useRouter();
  const { region } = useRegionContext();

  const gdprQuery = useGdprQuery({
    lazy: true,
  });

  const isGlobal = region === 'global';

  useBackendAnalyticsIntegration(!!viewer?.isAuthenticated);

  return (
    <ChakraProvider theme={theme}>
      <PageSEOMeta
        title="Republic — Invest in Startups, Crypto and More"
        metaTitle="Republic — Invest in Startups, Crypto and More"
        description="On Republic, anyone can invest in startups. Become an investor in cutting-edge private companies with as little as $50 ✅"
        url={toAbsoluteURL(applicationURLOrigin, router.asPath)}
        image={toAbsoluteURL(applicationURLOrigin, defaultCoverImage)}
        canonicalUrl="https://republic.com"
      />
      <MainLayout
        footerConfig={{
          disclaimer: (
            <>
              <MainLoggedOut />
              <Main />
            </>
          ),
        }}
        headerConfig={{
          hideRaiseCapitalCta: isGlobal,
          hideEventDisplay: isGlobal,
          hideBanner: isGlobal,
          hideTradeRepublicDisclosure: !gdprQuery.data?.gdprZone,
        }}
        sticky
      >
        <Head>
          <title>Republic — Invest in Startups, Crypto and More</title>
        </Head>
        <ZendeskScript
          email={viewer?.info?.email}
          firstName={viewer?.info?.firstName}
          lastName={viewer?.info?.lastName}
          zendeskKey={zendeskKey}
        />
        <ErrorBoundary>
          <PageContents />
        </ErrorBoundary>
      </MainLayout>
    </ChakraProvider>
  );
});

// Page Contents - it uses data from region context so needs be wrapped whole page
const PageContents = observer(function PageContents() {
  const analytics = useAnalytics();
  const { region } = useRegionContext();

  const carouselQuery = useCarouselsQuery({
    variables: { slug: 'home_us' },
    context: { doNotBatch: true },
  });

  useEffect(() => {
    if (analytics) {
      analytics.page({ name: 'Global Home', options: { region } });
    }
    carouselQuery.refetch();
  }, [analytics, region]);

  const CAROUSEL =
    region !== 'global' ? (
      <HeroCarousel
        desktopHeight="500px"
        carousel={carouselQuery.data?.carousels?.nodes?.[0]}
        dotsOverBanner
        centeredContent
        ctaSize="lg"
        isLoading={carouselQuery.isLoading}
        carouselItemCTAClicked={() => ({})}
        carouselItemSeen={() => ({})}
      />
    ) : null;

  return (
    <>
      {CAROUSEL}
      {region === 'us' ? (
        <UsPage />
      ) : region === 'global' ? (
        <GlobalPage />
      ) : null}
    </>
  );
});

export default withRegionContext(GlobalHome);
